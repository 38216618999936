
.anchor {
    text-decoration: none;
    color: #595B5D;
}

.cool-link { 
  font-size: 14px;
}



.custom_P {
    padding: 0rem 4rem 0rem 4rem;
    
  }
  
  @media screen and (max-width: 550px) {
    .custom_P {   
      padding: 0rem 1rem 0rem 1rem;
    
    }
}