.body {
    padding: 0rem 5rem 0rem 5rem;
    
   
 
    
}

  @media screen and (max-width: 550px) {
    .body {   
      padding: 0rem 1.8rem 0rem 1.8rem;
    }
  }