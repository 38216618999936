.body {
    padding: 0rem 5rem 0rem 12rem;
    
   
 
    
  }


  @media only screen and (max-width: 600px) {

    .body {   
      padding: 0rem 1.8rem 0rem 1.8rem;
    }
    
    
  }
  