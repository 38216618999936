.main {
  margin: 0;
  width: 100%;
  overflow-x: hidden;
}

.endtext {
  text-align: end;
}

.endbox {
  display: flex;
  justify-content: end;

}

.bodys {
    padding: 0rem 5rem 0rem 5rem;
    overflow-x: hidden;
   
 
    
  }

  .shadowx {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin: 10px;
  }
  .bg {

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='700' preserveAspectRatio='none' viewBox='0 0 1440 700'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1039%26quot%3b)' fill='none'%3e%3crect width='1440' height='700' x='0' y='0' fill='rgba(235%2c 242%2c 255%2c 1)'%3e%3c/rect%3e%3cpath d='M46 700L746 0L1296.5 0L596.5 700z' fill='url(%23SvgjsLinearGradient1040)'%3e%3c/path%3e%3cpath d='M537.2 700L1237.2 0L1739.7 0L1039.7 700z' fill='url(%23SvgjsLinearGradient1040)'%3e%3c/path%3e%3cpath d='M1391 700L691 0L389.5 0L1089.5 700z' fill='url(%23SvgjsLinearGradient1041)'%3e%3c/path%3e%3cpath d='M896.8 700L196.79999999999995 0L-199.20000000000005 0L500.79999999999995 700z' fill='url(%23SvgjsLinearGradient1041)'%3e%3c/path%3e%3cpath d='M1013.2121924677227 700L1440 273.21219246772273L1440 700z' fill='url(%23SvgjsLinearGradient1040)'%3e%3c/path%3e%3cpath d='M0 700L426.78780753227727 700L 0 273.21219246772273z' fill='url(%23SvgjsLinearGradient1041)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1039'%3e%3crect width='1440' height='700' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1040'%3e%3cstop stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1041'%3e%3cstop stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  }

  a{
    text-decoration: none;
  }
  
  @media screen and (max-width: 550px) {
    .bodys {   
      padding: 0rem 1.8rem 0rem 1.8rem;

     

    
    }

    .endtext {
     text-align: center;
    }

    .endbox {
      display: flex;
      justify-content: center;margin-top: 5px;

    
    }
  }